import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react';
import SkipValidateAnswer from '../SkipValidateAnswer';
import { TiMinus } from 'react-icons/ti';
import PortfoliTableBody from './PortfolioTableBody';
import { v4 as uuidv4 } from "uuid";

const PortfolioMultiTable = ({
  question,
  updateTemplateState,
  portfolioData,
  goToPreviousQuestionAnswer,
  filteredPortfolioData,
  sourceType,
  userAnswered
}) => {
  const [portfolioColumns, setPortfolioColumns] = useState([]);
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [portfolioAverages, setPortfolioAverages] = useState({
    Weight: 0,
    '1y performance': 0,
    '3y performance': 0,
    '5y performance': 0,
    'ongoing_charge': 0,
  });
  const [userAnsweredData, setUserAnsweredData] = useState([]);
  const getText = (textvalue) => {
    if (textvalue === 'Weight' || textvalue === 'ongoing_charge' ) {
      return 'Total: ';
    } else if (
      textvalue === '1y performance' ||
      textvalue === '3y performance' ||
      textvalue === '5y performance'
    ) {
      return 'Average:';
    } else {
      return '';
    }
  };
  const getTextValue = (textvalue) => {
    if (
      textvalue === 'Weight' ||
      textvalue === '1y performance' ||
      textvalue === '3y performance' ||
      textvalue === '5y performance' ||
      textvalue === 'ongoing_charge'
    ) {
      return parseFloat(portfolioAverages[textvalue]).toFixed(2) + '%';
    } else {
      return '';
    }
  };
  // Function to add a new dictionary
  const addNewAsset = () => {
    const newAsset = {};
    portfolioColumns.forEach((column) => {
      newAsset[column] = '';
    });
    // Add new asset to the array
    setData((prevData) => [...prevData, newAsset]);
  };
  const vallidateAnswer = () => {
    let isAssetnamePresent = true;
    userAnsweredData.forEach((rowdata) => {
      if (!rowdata['Asset Name'].length) {
        isAssetnamePresent = false;
      }
    });
    if (!isAssetnamePresent) {
      setErrorMessage('Asset Name cannot be empty');
    } else if (portfolioAverages.Weight === 100) {
      let portfolioAllDetails = {
        portfolioDetails: userAnsweredData,
        weightAverage: portfolioAverages.Weight,
        "1YearAverage": portfolioAverages["1y performance"],
        "3YearAverage": portfolioAverages["3y performance"],
        "5YearAverage": portfolioAverages["5y performance"],
        ongoingChargeAverage: portfolioAverages.ongoing_charge,
      };
      let res = { data: portfolioAllDetails }
      userAnswered(res)
    } else {
      setErrorMessage('Portfolio sum should be 100');
    }
  };
  const removePortfolioBody = (index) => {
    setData((prevData) => prevData.filter((_, i) => i !== index));
    setUserAnsweredData((prevData) => prevData.filter((_, i) => i !== index));
  };
  const updateUserAnsweredDetails = (index, values) => {
    setUserAnsweredData((prevData) => {
      const newData = [...prevData];
      newData[index] = values;
      return newData;
    });
  };
  const handleSearchTermChange = (index, value) => {
    setData((prevData) => {
      // Create a new array
      const newData = [...prevData];
      // Update only the assetName at the specific index
      if (newData[index]) {
        newData[index] = { ...newData[index], assetName: value };
      }
      return newData;
    });
  };
  const goToBackQuestion = () => {
    goToPreviousQuestionAnswer();
  };
  const changeDynamicHeigt = () =>{
    data.map((row,index)=>{
    const rowElement = document.getElementsByClassName("row_" + index)[0];
    const rowMinusElement = document.getElementById("row_minus_" + index);
    if (rowElement && rowMinusElement) {
      requestAnimationFrame(() => {
        rowMinusElement.style.height = `${rowElement.offsetHeight}px`; // Use px for setting height
    });
    } else {
        console.log(
            `Element not found: ${rowElement ? "row_minus_" + index : "row_" + index}`
        );
    }
    })
  }
  const uniqueKeysRef = useRef(new Map()); // Stores stable keys
  // Ensure each row gets a persistent unique key
  data.forEach((row) => {
    if (!uniqueKeysRef.current.has(row)) {
      uniqueKeysRef.current.set(row, uuidv4());
    }
  });
  useEffect(() => {
    setErrorMessage('');
    let tempaverages = { ...portfolioAverages }; // Create a copy to avoid directly mutating state
    Object.entries(portfolioAverages).forEach(([key, value]) => {
      let res = 0;
      if (key === 'Weight') {
        userAnsweredData.forEach((rowdata) => {
          const performance = parseFloat(rowdata[key]);
          if (rowdata.hasOwnProperty(key) && !isNaN(performance)) {
            res += performance;
          }
        });
        tempaverages[key] = res;
      } else {
        let PerformanceWeight = 0
        userAnsweredData.forEach((rowdata) =>{
          if (
            rowdata.hasOwnProperty(key) &&
            rowdata['Weight'] !== "" &&
            rowdata['Weight'] !== null &&
            rowdata['Weight'] !== undefined &&
            rowdata[key] !== "" &&
            rowdata[key] !== null &&
            rowdata[key] !== undefined &&
            !isNaN(rowdata[key]) &&
            !isNaN(rowdata['Weight'])
          ) {
            PerformanceWeight = PerformanceWeight + parseFloat(rowdata['Weight'])
          }
        })
        userAnsweredData.forEach((rowdata) => {
          const performance = parseFloat(rowdata[key]);
          const Weight = parseFloat(rowdata['Weight']);
          if (rowdata.hasOwnProperty(key) && !isNaN(performance) && !isNaN(Weight) ) {
            let tempval = performance*(Weight/PerformanceWeight)
            res = res + tempval;
          }
        });
        tempaverages[key] = res;
      }
    });
    setPortfolioAverages(tempaverages); // Update state after calculations
    changeDynamicHeigt()
  }, [userAnsweredData]);

  useEffect(() => {
    setPortfolioColumns(question.answers[0].PortfolioColumnNames);
    setUserAnsweredData(filteredPortfolioData);
    setData(filteredPortfolioData);
  }, [filteredPortfolioData]);
  return (
    <Flex w="100%" h="85vh" overflow="auto" p={5} flexDirection="column">
      <Flex justifyContent="space-between" mb="35px">
        <Box>
          <Text
            sx={{ textStyle: 'manropeHeading' }}
            fontSize="18px"
            fontWeight="600"
            lineHeight="20px"
            mb="14px"
          >
            {question.text}
          </Text>
          <Text
            sx={{ textStyle: 'manropeHeading' }}
            fontSize="14px"
            fontWeight="500"
            lineHeight="16px"
            color="avenirGrey.400"
          >
            {question.subText}
          </Text>
        </Box>
        <Button
          bg="white"
          color="avenirSuitabilityColor.600"
          p="8px 18px"
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          _hover={{ bg: 'white' }}
          border="1px solid"
          borderColor="avenirSuitabilityColor.600"
          borderRadius="12px"
          onClick={() => updateTemplateState(true)}
        >
          Import my portfolio from Excel file
        </Button>
      </Flex>
      <Flex alignItems="start">
        <Flex
          w="100%"
          borderRadius="8px"
          overflow="hidden"
          border="1px solid #E2E8F0"
        >
          {/* Table Section */}
          <Table
            variant="simple"
            sx={{
              '& th': {
                borderRight: '1px solid #E2E8F0',
                padding: '5px 10px',
                textStyle: 'manropeHeading',
                color: 'white',
                fontWeight: '700',
                fontSize: '14px',
                textTransform: 'none',
              },
              '& td': {
                borderRight: '1px solid #E2E8F0',
                padding: '5px 10px',
                textStyle: 'manropeHeading',
                color: '#1B2559',
                fontSize: '14px',
                fontWeight: '400',
              },
              '& tr:last-child td': {
                borderBottom: 'none',
              },
            }}
          >
            <Thead bg="avenirSuitabilityColor.800">
              <Tr>
                {portfolioColumns.length
                  ? portfolioColumns.map((columnName, index) => (
                      <Th color="white" key={index}>
                        {columnName === 'Isin' ? "ISIN" : columnName}
                      </Th>
                    ))
                  : null}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row, index) => (
                <PortfoliTableBody
                  key={uniqueKeysRef.current.get(row)}
                  keyIndex={index}
                  rowDetails={row}
                  updateUserAnsweredDetails={updateUserAnsweredDetails}
                  sourceType={sourceType}
                />
              ))}
            </Tbody>
          </Table>
        </Flex>
        {/* Extra Column */}
        <Flex
          ml={2}
          h="100%"
          flexDirection="column"
        >
          <Box h="43px"></Box>
          {data.map((_, index) => (
            <Flex
              id={"row_minus_"+index}
              key={index}
              alignItems="center"
              justifyContent="center"
              // Start from the second row
              visibility={index === 0 ? 'hidden' : 'visible'}
              onClick={() => removePortfolioBody(index)}
            >
              <TiMinus size="18px" color="#B1B1B1" cursor="pointer" />
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Box
        display="grid"
        w="100%"
        gridTemplateColumns={`20% repeat(${portfolioColumns.length -1}, 1fr)`} // Same number of columns as the table
        borderRadius="0 0 8px 8px" // Bottom corners rounded
        p="5px 18px"
      >
        {portfolioColumns.map((columnName, index) => (
          <Text
            key={index}
            color="avenirGrey.600"
            sx={{ textStyle: 'dmsansText' }}
            fontSize="14px"
            fontWeight="500"
            textAlign="center"
          >
            {getText(columnName)}{' '}
            <Text as="span" fontWeight="700">
              {getTextValue(columnName)}
            </Text>
          </Text>
        ))}
      </Box>
      <Flex justifyContent="space-between" mt={4}>
        <Button
          bg="white"
          color="avenirSuitabilityColor.600"
          p="8px 18px"
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          _hover={{ bg: 'white' }}
          border="1px solid"
          borderColor="avenirSuitabilityColor.600"
          borderRadius="12px"
          onClick={addNewAsset}
        >
          + Add an asset to this portfolio
        </Button>
      </Flex>
      {errorMessage.length ? (
        <Flex flexDirection="row-reverse" mt="auto">
          <Text
            sx={{ textStyle: 'manropeHeading' }}
            color="#B20D30"
            fontSize="12px"
            fontWeight="600"
          >
            {errorMessage}
          </Text>
        </Flex>
      ) : null}
      <Flex flexDirection="row-reverse" mt="auto">
        <SkipValidateAnswer
          //   skipQuestion={skipQuestion}
          vallidateAnswer={vallidateAnswer}
          goToBackQuestion={goToBackQuestion}
          alignFlexItems={true}
        />
      </Flex>
    </Flex>
  );
};

export default PortfolioMultiTable;
