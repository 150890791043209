import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {postIntellifloUserAuth} from '../../../../services/apiServices'

const OAuthCallback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const postAuth = async (authCode) =>{
    let responsebody = {
      'code': authCode
    }
    const res = await postIntellifloUserAuth(responsebody);
    if(res){
      window.location.href = '/suitability-report/integrations';
    }
  }
  useEffect(() => {
    const code = searchParams.get('code');
    const scope = searchParams.get('scope');
    const state = searchParams.get('state');
    const sessionState = searchParams.get('session_state');
    postAuth(code)
    // You can send the `code` to your backend for token exchange here
  }, [searchParams]);

  return <div>OAuth Callback: Handling authorization response...</div>;
};

export default OAuthCallback;
