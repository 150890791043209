import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Box,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex
} from '@chakra-ui/react';
import {
  InteliffloRedirectUrl,
  InteliffloClientId,
} from '../../../../static/constants';
import { deleteIntellifloUserAuth } from '../../../../services/apiServices';

const InteliffloLogin = () => {
  const userInfo = useSelector((state) => state.usrinf);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Function to generate a random nonce and state
  const generateNonceAndState = () => {
    return {
      nonce: crypto.randomUUID(),
      state: crypto.randomUUID(),
    };
  };

  // Function to redirect user to the authorization URL
  const fetchAuthorizationCode = () => {
    const { nonce, state } = generateNonceAndState();

    // Save nonce and state in localStorage for validation later
    localStorage.setItem('auth_nonce', nonce);
    localStorage.setItem('auth_state', state);

    // Construct the Authorization URL
    const authUrl = `https://identity.gb.intelliflo.net/core/connect/authorize?response_type=code&client_id=${encodeURIComponent(
      InteliffloClientId,
    )}&redirect_uri=${encodeURIComponent(
      InteliffloRedirectUrl,
    )}&scope=openid myprofile profile client_data client_financial_data offline_access firm_data fund_data&state=${state}&nonce=${nonce}`;

    // Open the authorization URL in a new tab
    window.open(authUrl, '_self'); // `_self` to open in the same tab, `_blank` for a new tab
  };
  const disconnectIntelliflo = async () => {
    await deleteIntellifloUserAuth();
    window.location.href = '/suitability-report/integrations';
  };

  return (
    <Box>
      {!userInfo.isIntellifloSynchronised ? (
        <Button
          bg="avenirSuitabilityColor.600"
          color="white"
          sx={{ textStyle: 'dmsansText' }}
          fontSize="14px"
          _hover={{ bg: 'avenirSuitabilityColor.600' }}
          borderRadius="12px"
          h="36px"
          onClick={fetchAuthorizationCode}
        >
          Connect
        </Button>
      ) : (
        <Button
          bg="white"
          color="avenirSuitabilityColor.600"
          sx={{ textStyle: 'dmsansText' }}
          fontSize="14px"
          _hover={{ bg: 'white' }}
          borderRadius="12px"
          h="36px"
          onClick={onOpen}
          border="1px solid"
          borderColor="avenirSuitabilityColor.600"
        >
          Disconnect
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            sx={{ textStyle: 'dmsansNormal' }}
            fontSize="24px"
            fontWeight="700"
            color="#1B2559"
            mt="20px"
          >
            <Flex w="100%" justifyContent="center">
              Synchronisation Warning
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Flex w="100%" justifyContent="center" flexDirection="column" alignItems="center">
              <Text
                sx={{ textStyle: 'dmsansNormal' }}
                fontSize="16px"
                fontWeight="400"
                color="#1B2559"
                textAlign="center"
              >
                This operation will prevent any automated synchronisation
                between Avenir and Intelliflo. However, all the currently
                synchronised resources (clients, etc.) are kept in Avenir. You
                can remove them manually if you desire.
              </Text>
              <Text
                 sx={{ textStyle: 'dmsansNormal' }}
                 fontSize="16px"
                 fontWeight="700"
                 color="#1B2559"
                 textAlign="center"
                 mt="15px"
              >
                You can synchronise your accounts again whenever you want.
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="white"
              color="avenirSuitabilityColor.600"
              sx={{ textStyle: 'dmsansText' }}
              fontSize="14px"
              _hover={{ bg: 'white' }}
              borderRadius="12px"
              h="36px"
              onClick={onClose}
              border="1px solid"
              p="0 18px"
              borderColor="avenirSuitabilityColor.600"
            >
              Cancel
            </Button>
            <Button
              bg="avenirSuitabilityColor.600"
              color="white"
              sx={{ textStyle: 'dmsansText' }}
              fontSize="14px"
              _hover={{ bg: 'avenirSuitabilityColor.600' }}
              borderRadius="12px"
              h="36px"
              p="0 18px"
              onClick={disconnectIntelliflo}
              ml="20px"
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default InteliffloLogin;
