/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { CreateReportAPI, setNumberOfPostMeetingSummaryInitiated } from '../../../services/apiServices';
import CryptoJS from 'crypto-js';

import CreateReport from './index';
const EditMeeting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = useSelector((state) => state.mauth);
  const { meetingId, templateID, reportName, clientUid, reportUID } =
    location.state || {}; // Extract the passed props
  const [showLoadingModal, setShowLoadingModal] = useState(true);
  useEffect(() => {
    const hasVisited = sessionStorage.getItem('hasVisitedEditMeeting');
    if (hasVisited === "1") {
      navigate('/suitability-report/meetings');
    } else {
      sessionStorage.setItem('hasVisitedEditMeeting', "1");
      fetchData()
    }
  }, [navigate]);


    const fetchData = async () => {
      let res = {};
      if (reportUID) {
        res = {
          reportUID: {
            reportUID: reportUID,
          },
          templateUID: templateID,
          reportName: reportName,
        };
      } else {
        const resbody = {
          clientUID: clientUid,
          templateUID: templateID,
          meetingID: meetingId,
        };
        const response = await CreateReportAPI(resbody, userInfo.mid);
        res = {
          reportUID: response,
          reportName: reportName,
        };
        await setNumberOfPostMeetingSummaryInitiated()
      }
      const jsonString = JSON.stringify(res);
      const base64Encoded = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(jsonString));
      navigate(`/suitability-report/edit-report/${base64Encoded}`)
      setTimeout(() => {
        setShowLoadingModal(false);
      }, 300);
    };



  return (
    <Box>
      {showLoadingModal ? (
        <Box>
          <Modal isOpen isCentered>
            <ModalOverlay />
            <ModalContent
              borderRadius="16px"
              maxW="500px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <ModalBody
                display="flex"
                justifyContent="center"
                alignItems="center"
                m="20px"
              >
                <Flex alignItems="center">
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="avenirSuitabilityColor.600"
                    size="xl"
                  />
                  <Text
                    fontFamily="DM Sans"
                    fontWeight="500"
                    fontSize="lg"
                    color="gray.600"
                    ml="10px"
                  >
                    Extracting information from your recording...
                  </Text>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      ) : (
        <CreateReport
          mid={userInfo.mid}
        ></CreateReport>
      )}
    </Box>
  );
};
export default EditMeeting;
