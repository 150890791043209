import { PublicClientApplication } from '@azure/msal-browser';
// Function to determine the base URL dynamically
const getBaseURL = () => {
  const { hostname } = window.location;

  if (hostname.includes("localhost")) {
    return "http://localhost:3000";
  } else if (hostname.includes("pre-prod")) {
    return "https://pre-prod-suit-direct-indexing-41411.web.app";
  } else {
    return "https://suitability.web.app"; // Default to production
  }
};
const BASE_URL = getBaseURL();

const msalConfig = {
  auth: {
    clientId: '726d88f0-74e8-4479-9867-b65fb8f38163',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: `${BASE_URL}/suitability-report/meetings`, // or your production URL
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const initializeMsalInstance = async () => {
  await msalInstance.initialize();
};

export { msalInstance, initializeMsalInstance };
