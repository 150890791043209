import React, { useEffect, useState /*useRef*/ } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  // Input,
  Stack,
  Text,
  Grid,
  GridItem,
  useColorModeValue,
  // FormLabel,
  // FormControl,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
// import { IoCloudUploadOutline } from 'react-icons/io5';
import {
  // getClientFooterDetails,
  // postClientFooterDetails,
  // postClientHeadetDetails,
  // getClienHeaderDetails,
  getUserColor,
  postUserColor,
} from '../../../services/apiServices';
const SuitabilityAccountSettings = () => {
  // const inputRef = useRef();
  const userInfo = useSelector((state) => state.usrinf);
  const boxBg = useColorModeValue('white', 'gray.800');
  // const [uploadedFile, setUploadedFile] = useState();
  // const [headerName, setHeaderName] = useState('');
  // const [headerImg, setHeaderImg] = useState();
  // const [fileUrl, setFileUrl] = useState('');
  // const [advisorData, setAdvisorData] = useState({
  //   advisorName: '',
  //   advisorNumber: '',
  //   advisorAddress: '',
  //   qualifications: '',
  // });
  const [selectedColor, setSelectedColor] = useState('');
  const colorScheme = {
    Gray: '#707EAE',
    Navy: '#24388A',
    Orange: '#FA6B25',
    Amber: '#F59E0B',
    Yellow: '#FBCF33',
    Lime: '#7CB342',
    Green: '#15803D',
    Teal: '#0F766E',
    Cyan: '#0891B2',
    Blue: '#0D47A1',
    Indigo: '#4338CA',
    Purple: '#6B21A8',
    Pink: '#DB2777',
    Red: '#EA0606',
  };
  const handleSelectColor = (colorName) => {
    setSelectedColor(colorName);
  };
  // Handle input change
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setAdvisorData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };
  //resize the image
  // const resizeImage = (file, width, height) => {
  //   return new Promise((resolve, reject) => {
  //     const img = new window.Image(); // Use window.Image to refer to the native Image constructor
  //     img.src = URL.createObjectURL(file);

  //     img.onload = () => {
  //       const canvas = document.createElement('canvas');
  //       canvas.width = width;
  //       canvas.height = height;

  //       const ctx = canvas.getContext('2d');
  //       ctx.drawImage(img, 0, 0, width, height);

  //       canvas.toBlob((blob) => {
  //         resolve(blob);
  //       }, file.type);
  //     };

  //     img.onerror = (error) => reject(error);
  //   });
  // };
  // Function to get the dimensions of an image
  // const getImageDimensions = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const img = new window.Image(); // Use the native Image constructor
  //     img.src = URL.createObjectURL(file);

  //     img.onload = () => {
  //       const dimensions = {
  //         width: img.width,
  //         height: img.height,
  //       };
  //       resolve(dimensions);
  //     };

  //     img.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  //Handle header image
  // const handleFileChange = async (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const fileTypes = ['image/ico', 'image/png', 'image/gif', 'image/jpeg'];
  //     if (!fileTypes.includes(file.type)) {
  //       console.log('wrong format');
  //       return;
  //     }
  //     const { width: originalWidth, height: originalHeight } =
  //       await getImageDimensions(file);
  //     const aspectRatio = originalWidth / originalHeight;
  //     // Handle the file upload logic here
  //     setHeaderName(file.name);
  //     const resizedBlob = await resizeImage(file, 30 * aspectRatio, 30);
  //     // Create a new File object with a proper extension
  //     const resizedFile = new File([resizedBlob], `${file.name}`, {
  //       type: file.type,
  //     });
  //     setUploadedFile(resizedFile); // Directly set the file object
  //     setFileUrl(URL.createObjectURL(resizedFile));
  //     event.target.value = null;
  //   }
  // };
  // const handleClick = () => {
  //   inputRef.current.click();
  // };
  //save footer data in the DB
  // const saveFooterDetails = () => {
  //   if (
  //     advisorData.advisorName ||
  //     advisorData.advisorNumber ||
  //     advisorData.advisorAddress ||
  //     advisorData.qualifications
  //   ) {
  //     postClientFooterDetails(advisorData);
  //   }
  // };
  //save header Data in the DB
  const saveHeaderdetails = async () => {
    // const res = {
    //   headerName: headerName,
    //   headerImage: uploadedFile,
    // };
    try {
      // if (res.headerName) {
      //   await postClientHeadetDetails(res);
      // }
      if (selectedColor) {
        await postReportUserColor();
      }
      await fetchHeaderDetails();
      await getReportUserColor();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  const fetchHeaderDetails = async () => {
    // setFileUrl('');
    try {
      // const headerDetails = await getClienHeaderDetails();
      // setHeaderImg(headerDetails.headerImage);
    } catch (error) {
      console.error('Failed to fetch header details:', error);
    }
  };
  // const fetchFooterDetails = async () => {
  //   try {
  //     const footerDetails = await getClientFooterDetails();
  //     delete footerDetails.footer;
  //     setAdvisorData({
  //       advisorName: footerDetails.advisorName || '',
  //       advisorNumber: footerDetails.advisorNumber || '',
  //       advisorAddress: footerDetails.advisorAddress || '',
  //       qualifications: footerDetails.qualifications || '',
  //     });
  //   } catch (error) {
  //     console.error('Failed to fetch footer details:', error);
  //   }
  // };
  const findColorName = (colorCode) => {
    const colorName = Object.keys(colorScheme).find(
      (key) => colorScheme[key] === colorCode,
    );
    return colorName || 'Color not found';
  };
  const getReportUserColor = async () => {
    const res = await getUserColor();
    const parsedRes = JSON.parse(res);
    if (parsedRes.defaultColor) {
      setSelectedColor(findColorName(parsedRes.defaultColor));
    }
  };
  const postReportUserColor = async () => {
    const res = {
      defaultColor: colorScheme[selectedColor],
    };
    await postUserColor(res);
  };
  useEffect(() => {
    const fetchData = async () => {
      await fetchHeaderDetails();
      // await fetchFooterDetails();
      await getReportUserColor();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box p={6} h="100vh" w="80vw" overflow="auto">
      <Box mb="20px">
        <Text sx={{ textStyle: 'dmsansNormal' }}>
          {userInfo.organisationName} / Settings
        </Text>
        <Text sx={{ textStyle: 'dmsansHeading' }}>Account Settings</Text>
      </Box>

      <Grid templateColumns="repeat(2, 1fr)" gap={6} bg="white">
        {/* Your Branding */}
        <GridItem
          colSpan={2}
          colStart={1}
          borderRadius="30px"
          bg="white"
          boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
          w="780px"
        >
          <Box p={6} bg={boxBg} borderRadius="md" boxShadow="sm">
            <Text
              size="md"
              mb="0px"
              sx={{ textStyle: 'dmsansHeading' }}
              fontSize="24px"
            >
              Your Company Details
            </Text>
            <Text sx={{ textStyle: 'dmsansNormal' }} mb="16px">
              Here you can change your account information
            </Text>
            {/* <Text sx={{ textStyle: 'dmsansNormal' }} color="#1B2559" mb="10px">
              Upload your company logo
            </Text> */}
            <Stack spacing={4}>
              {/* <Flex alignItems="center">
                <Image
                  src={fileUrl.length ? fileUrl : headerImg}
                  objectFit="contain"
                  boxSize="120px"
                  border="1px solid"
                  borderColor="avenirGrey.100"
                  mr="20px"
                ></Image>
                <Flex flexDirection="column" h="120px">
                  <Button
                    color="white"
                    bg="avenirSuitabilityColor.600"
                    mr={4}
                    borderRadius="12px"
                    fontSize="20px"
                    w="110px"
                    h="40px"
                    leftIcon={<Icon as={IoCloudUploadOutline} boxSize="20px" />}
                    _hover={{ bg: 'avenirSuitabilityColor.600' }}
                    onClick={handleClick}
                  >
                    <Text fontSize="14px">Upload</Text>
                  </Button>
                  <input
                    type="file"
                    accept=".ico,.png,.gif,.jpg,.jpeg"
                    style={{ display: 'none' }}
                    ref={inputRef}
                    onChange={handleFileChange}
                  />
                  <Text
                    w="300px"
                    mt="20px"
                    fontSize="14px"
                    lineHeight="-0.28px"
                    sx={{ textStyle: 'dmsansHeading' }}
                    fontWeight="500"
                  >
                    Upload your logo in PNG or JPG.
                  </Text>
                  <Text
                    w="300px"
                    fontSize="14px"
                    lineHeight="-0.28px"
                    sx={{ textStyle: 'dmsansHeading' }}
                    fontWeight="700"
                  >
                    This will appear in your report header.
                  </Text>
                </Flex>
              </Flex> */}
              <Text
                sx={{ textStyle: 'dmsansNormal' }}
                color="#1B2559"
                mb="-4px"
              >
                Select Your Color Scheme
              </Text>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  w="260px"
                  textAlign="left"
                  sx={{ textStyle: 'dmsansNormal' }}
                  fontSize="16px"
                  color="#8F9BBA"
                  fontWeight="500"
                  bg="white"
                  border="1px solid"
                  borderColor="avenirGrey.200"
                  _hover={{ bg: 'white' }}
                >
                  {selectedColor ? selectedColor : 'Pick your Color Scheme'}
                </MenuButton>
                <MenuList
                  maxHeight="200px"
                  overflowY="scroll"
                  sx={{
                    '&::-webkit-scrollbar': {
                      width: '8px', // Width of the scrollbar
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)', // Scrollbar thumb color
                      borderRadius: '24px',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'white', // Scrollbar track color
                    },
                    overflowY: 'scroll !important',
                    overflowX: 'hidden', // Disable horizontal scroll
                  }}
                  p="6px 4px 8px 10px"
                >
                  {Object.entries(colorScheme).map(
                    ([colorName, colorValue], index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleSelectColor(colorName)}
                      >
                        <Flex align="center">
                          <Box
                            h="12px"
                            w="12px"
                            bg={colorValue}
                            mr="18px"
                            border="1px solid"
                            borderColor="rgba(0, 0, 0, 0.2)"
                          />
                          <Text
                            sx={{ textStyle: 'dmsansNormal' }}
                            fontSize="16px"
                            color="#1B2559"
                          >
                            {colorName}
                          </Text>
                        </Flex>
                      </MenuItem>
                    ),
                  )}
                </MenuList>
              </Menu>
              <Flex justifyContent="end">
                <Button
                  alignSelf="flex-start"
                  _hover={{ bg: 'avenirSuitabilityColor.600' }}
                  color="white"
                  bg="avenirSuitabilityColor.600"
                  borderRadius="12px"
                  onClick={saveHeaderdetails}
                  sx={{ textStyle: 'dmsansNormal' }}
                  fontSize="14px"
                >
                  Save Changes
                </Button>
              </Flex>
            </Stack>
          </Box>
        </GridItem>

        {/* Footer */}
        {/* <GridItem
          colSpan={2}
          bg="white"
          borderRadius="30px"
          boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
          w="780px"
        >
          <Box p={6} bg={boxBg} borderRadius="md" boxShadow="sm">
            <Text
              size="md"
              mb="0px"
              sx={{ textStyle: 'dmsansHeading' }}
              fontSize="24px"
            >
              Footer
            </Text>
            <Text mb="16px" sx={{ textStyle: 'dmsansNormal' }}>
              Set the footer copy for all your reports here.
            </Text>

            <Stack spacing={4}>
              <Text
                sx={{ textStyle: 'dmsansNormal' }}
                color="#1B2559"
                mb="10px"
              >
                Footer Information
              </Text>
              <Grid templateColumns="repeat(2, 1fr)" gap="8px">
                <FormControl>
                  <FormLabel
                    sx={{ textStyle: 'dmsansNormal' }}
                    color="#1B2559"
                    fontSize="14px"
                  >
                    Adviser Name
                  </FormLabel>
                  <Input
                    name="advisorName"
                    placeholder="John Doe"
                    borderRadius="16px"
                    focusBorderColor="avenirSuitabilityColor.600"
                    value={advisorData.advisorName}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    sx={{ textStyle: 'dmsansNormal' }}
                    color="#1B2559"
                    fontSize="14px"
                  >
                    Adviser Number
                  </FormLabel>
                  <Input
                    name="advisorNumber"
                    placeholder="07.33.34.222.22"
                    borderRadius="16px"
                    focusBorderColor="avenirSuitabilityColor.600"
                    value={advisorData.advisorNumber}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    sx={{ textStyle: 'dmsansNormal' }}
                    color="#1B2559"
                    fontSize="14px"
                  >
                    Qualifications
                  </FormLabel>
                  <Input
                    name="qualifications"
                    placeholder="Uncharted Financial Planner"
                    borderRadius="16px"
                    focusBorderColor="avenirSuitabilityColor.600"
                    value={advisorData.qualifications}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    sx={{ textStyle: 'dmsansNormal' }}
                    color="#1B2559"
                    fontSize="14px"
                  >
                    Adviser Address
                  </FormLabel>
                  <Input
                    name="advisorAddress"
                    placeholder="10 Downing Street, London"
                    borderRadius="16px"
                    focusBorderColor="avenirSuitabilityColor.600"
                    value={advisorData.advisorAddress}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Flex justifyContent="end">
                <Button
                  alignSelf="flex-start"
                  _hover={{ bg: 'avenirSuitabilityColor.600' }}
                  color="white"
                  bg="avenirSuitabilityColor.600"
                  borderRadius="12px"
                  onClick={saveFooterDetails}
                  sx={{ textStyle: 'dmsansNormal' }}
                  fontSize="14px"
                >
                  Save Changes
                </Button>
              </Flex>
            </Stack>
          </Box>
        </GridItem> */}
        {/* Security Measures */}
        <GridItem
          colSpan={2} // Take full width
          bg="white"
          borderRadius="30px"
          boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
          w="780px"
        >
          <Box p={6} bg={boxBg} borderRadius="md" boxShadow="sm">
            <Text
              size="md"
              mb="15px"
              sx={{ textStyle: 'dmsansHeading' }}
              fontSize="24px"
            >
              Security Measures
            </Text>
            <Stack spacing={4}>
              <Flex justifyContent="space-between">
                <Text sx={{ textStyle: 'dmsansText' }}>
                  Data Encryption at Rest (AES-256)
                </Text>
                <Text
                  sx={{ textStyle: 'dmsansText' }}
                  color="green.500"
                  bg="green.100"
                  p="3px 10px"
                  borderRadius="10px"
                >
                  Enabled
                </Text>
              </Flex>
              <Box
                as="hr"
                border="0"
                height="1px"
                bg="#E9EDF7"
                my="3px" // margin top and bottom
              />
              <Flex justifyContent="space-between">
                <Text sx={{ textStyle: 'dmsansText' }}>
                  UK-Only Data Storage
                </Text>
                <Text
                  sx={{ textStyle: 'dmsansText' }}
                  color="green.500"
                  bg="green.100"
                  p="3px 10px"
                  borderRadius="10px"
                >
                  Enabled
                </Text>
              </Flex>
              <Box
                as="hr"
                border="0"
                height="1px"
                bg="#E9EDF7"
                my="3px" // margin top and bottom
              />
              <Flex justifyContent="space-between">
                <Text sx={{ textStyle: 'dmsansText' }}>
                  Secure Data Transfer
                </Text>
                <Text
                  sx={{ textStyle: 'dmsansText' }}
                  color="green.500"
                  bg="green.100"
                  p="3px 10px"
                  borderRadius="10px"
                >
                  Enabled
                </Text>
              </Flex>
            </Stack>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default SuitabilityAccountSettings;
