import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  VStack,
  HStack,
  Image,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import microsoftLogo from 'assets/img/SuitabilityReport/logos_microsoft-teams.png';
import googleLogo from 'assets/img/SuitabilityReport/logos_google-meet.png';
import zoomLogo from 'assets/img/SuitabilityReport/logos_zoom.png';
import Xplan from 'assets/img/SuitabilityReport/Xplan.png';
import Inteliflio from 'assets/img/SuitabilityReport/Intelliflo.png';
import { useNavigate } from 'react-router-dom';
import { msalInstance } from '../../../../microsoftMSAL/msalConfig';
//components
import IntellifloLogin from './InteliffloLogin';
const IntegrationSection = () => {
  const navigate = useNavigate();
  const navigatetoMeetings = () => {
    navigate('/suitability-report/meetings');
  };
  const initializeMsal = async () => {
    try {
      await msalInstance.initialize(); // This ensures MSAL is initialized before making API calls
    } catch (error) {
      console.error('Error initializing MSAL:', error);
    }
  };
  const [isMircosoftLoggedIn, setIsMircosoftLoggedIn] = useState(false);
  useEffect(() => {
    const initializeMsalAndFetchData = async () => {
      await initializeMsal(); // Initialize MSAL instance first
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        setIsMircosoftLoggedIn(true); // Set the active account
      }
    };

    initializeMsalAndFetchData();
  }, []);
  return (
    <Flex justify="space-between" wrap="wrap" mt="40px">
      {/* Recordings Section */}
      <Box
        w="49%"
        minHeight="40vh"
        overflow="auto"
        p="16px 18px 18px 23px"
        bg="white"
        borderRadius="12px"
        boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      >
        <Text
          sx={{ textStyle: 'dmsansText' }}
          fontSize="18px "
          fontWeight="700"
          lineHeight="32px"
          letterSpacing="-0.36px"
        >
          Recordings
        </Text>
        <Text
          sx={{ textStyle: 'dmsansText' }}
          color="secondaryGray.600"
          fontSize="14px"
          fontWeight="400"
          lineHeight="26px"
          letterSpacing="-0.28px"
        >
          Avenir will get back the transcript for your recordings and create
          summaries, fact-find, or send the information back to your CRM.
        </Text>
        <VStack mt="32px" spacing="40px" align="start">
          <HStack justify="space-between" w="100%">
            <HStack>
              <Flex w="70px">
                <Image
                  src={microsoftLogo}
                  alignSelf="center"
                  justifySelf="center"
                  w="34px"
                  h="32px"
                />
              </Flex>
              <Text
                sx={{ textStyle: 'dmsansText' }}
                fontSize="15px "
                fontWeight="700"
                lineHeight="25px"
                letterSpacing="-0.36px"
              >
                Microsoft Teams
              </Text>
            </HStack>
            {!isMircosoftLoggedIn ? (
              <Button
                bg="avenirSuitabilityColor.600"
                color="white"
                sx={{ textStyle: 'dmsansText' }}
                fontSize="14px"
                _hover={{ bg: 'avenirSuitabilityColor.600' }}
                borderRadius="12px"
                h="36px"
                onClick={navigatetoMeetings}
              >
                Connect
              </Button>
            ) : (
              <Button
                bg="white"
                color="green.500"
                _hover={{ bg: 'white' }}
                borderRadius="12px"
                h="36px"
                border="1px solid"
                borderColor="green.500"
                display="flex"
                alignItems="center"
                justifyContent="center"
                px={4}
              >
                <CheckIcon color="green.500" boxSize={4} />
              </Button>
            )}
          </HStack>
          <HStack justify="space-between" w="100%">
            <HStack>
              <Flex w="70px">
                <Image
                  src={zoomLogo}
                  alignSelf="center"
                  justifySelf="center"
                  w="52px"
                  h="13px"
                />
              </Flex>
              <Text
                sx={{ textStyle: 'dmsansText' }}
                fontSize="15px "
                fontWeight="700"
                lineHeight="25px"
                letterSpacing="-0.36px"
              >
                Zoom
              </Text>
            </HStack>
            <Button
              color="white"
              bg="avenirGrey.200"
              sx={{ textStyle: 'dmsansText' }}
              fontSize="15px "
              fontWeight="700"
              lineHeight="25px"
              letterSpacing="-0.36px"
            >
              Coming soon
            </Button>
          </HStack>
          <HStack justify="space-between" w="100%">
            <HStack>
              <Flex w="70px">
                <Image
                  src={googleLogo}
                  alignSelf="center"
                  justifySelf="center"
                  w="31px"
                  h="20px"
                />
              </Flex>
              <Text
                sx={{ textStyle: 'dmsansText' }}
                fontSize="15px "
                fontWeight="700"
                lineHeight="25px"
                letterSpacing="-0.36px"
              >
                Google Meet
              </Text>
            </HStack>
            <Button
              color="white"
              bg="avenirGrey.200"
              sx={{ textStyle: 'dmsansText' }}
              fontSize="15px "
              fontWeight="700"
              lineHeight="25px"
              letterSpacing="-0.36px"
            >
              Coming soon
            </Button>
          </HStack>
        </VStack>
      </Box>

      {/* CRM Section */}
      <Box
        w="44%"
        h="max-content"
        overflow="auto"
        p="16px 18px 18px 23px"
        bg="white"
        borderRadius="12px"
        boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      >
        <Text
          sx={{ textStyle: 'dmsansText' }}
          fontSize="18px "
          fontWeight="700"
          lineHeight="32px"
          letterSpacing="-0.36px"
        >
          CRM
        </Text>
        <Text
          sx={{ textStyle: 'dmsansText' }}
          color="secondaryGray.600"
          fontSize="14px"
          fontWeight="400"
          lineHeight="26px"
          letterSpacing="-0.28px"
        >
          Avenir will pull the information from your CRM to fuel your report,
          and send back the extracted data.
        </Text>
        <VStack mt="32px" spacing="40px" align="start">
          <HStack justify="space-between" w="100%">
            <HStack>
              <Flex w="70px">
                <Image
                  src={Inteliflio}
                  alignSelf="center"
                  justifySelf="center"
                  w="59px"
                  h="38px"
                />
              </Flex>
              <Text
                sx={{ textStyle: 'dmsansText' }}
                fontSize="15px "
                fontWeight="700"
                lineHeight="25px"
                letterSpacing="-0.36px"
              >
                Intelligent Office
              </Text>
            </HStack>
            <IntellifloLogin></IntellifloLogin>
          </HStack>
          <HStack justify="space-between" w="100%">
            <HStack>
              <Flex w="70px">
                <Image
                  src={Xplan}
                  alignSelf="center"
                  justifySelf="center"
                  w="44px"
                  h="32px"
                />
              </Flex>
              <Text
                sx={{ textStyle: 'dmsansText' }}
                fontSize="15px "
                fontWeight="700"
                lineHeight="25px"
                letterSpacing="-0.36px"
              >
                Xplan
              </Text>
            </HStack>
            <Button
              color="white"
              bg="avenirGrey.200"
              sx={{ textStyle: 'dmsansText' }}
              fontSize="15px "
              fontWeight="700"
              lineHeight="25px"
              letterSpacing="-0.36px"
            >
              Coming soon
            </Button>
          </HStack>
        </VStack>
      </Box>
    </Flex>
  );
};

export default IntegrationSection;
