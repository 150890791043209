import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  VStack,
  HStack,
  Icon,
  Tooltip,
  Spinner,
  Image
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { LuPlusCircle } from 'react-icons/lu';
import V1CreateNewClient from './V1CreateNewClient';
import { msalInstance } from '../../../microsoftMSAL/msalConfig';
import { GoPeople } from 'react-icons/go';
import IntellifloShortImage from '../../../assets/img/SuitabilityReport/IntellifloShortImage.png'
import {
  createClient,
  getMicrosfotMeetingTranscript,
  deleteMeetingSummary,
  getUserClientList
} from '../../../services/apiServices';
const tokenRequest = {
  scopes: [
    'user.read',
    'OnlineMeetingRecording.Read.All',
    'OnlineMeetings.Read',
    'Calendars.Read',
    'OnlineMeetingTranscript.Read.All',
  ],
};
const MeetingCard = ({
  meeting,
  userClientList,
  updateMeetingList,
  updateClientList,
}) => {
  const actions = [
    meeting.transcripts ? 'Access meeting transcript' : null,
    meeting.summarizedMeeting ? 'Delete summary from Avenir' : null,
  ].filter(Boolean);
  const navigate = useNavigate();
  useEffect(() => {
    setClientList(userClientList);
  }, [userClientList]);
  const debounceTimer = useRef(null); // useRef to store timeout
  const [reduxClientLists] = useState(userClientList);
  const [clientList, setClientList] = useState(reduxClientLists);
  const [showClientList, setShowClientList] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filteredCLientlist, setFilteredCLientlist] = useState([]);
  const [showCreateClientModal, setShowCreateClientModal] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientId, setClientId] = useState('');
  const [showClientsLoader, setShowClientsLoader] = useState(false)
  // eslint-disable-next-line
  const [errorMessage, serErrorMessage] = useState('');
  const [createdClientName, setCreatedClientName] = useState({
    firstname: '',
    lastname: '',
  });
  // Trim function to limit string length
  const trimText = (text, length) =>
    text.length > length ? text.substring(0, length) + '...' : text;
  // Get the first two attendees trimmed to 30 characters each
  const displayText = meeting.attendees
    .slice(0, 2)
    .map((email) => trimText(email, 15))
    .join('\n');
  // Tooltip text for the full list with each attendee trimmed to 40 characters
  const tooltipText =  meeting.attendees
    .map((email) => trimText(email, 40))
    .join('\n');
  const addNewCleint = () => {
    let [clientfirstname, ...rest] = clientName.split(' ');
    let clientlastname = rest.join(' ');
    setCreatedClientName({
      firstname: clientfirstname,
      lastname: clientlastname,
    });
    setShowCreateClientModal(true);
  };
  const closeClient = () => {
    setShowCreateClientModal(false);
  };
  const handleClientNameBlur = () => {
    setShowClientList(false);
  };
  const createSummary = () => {
    sessionStorage.setItem('hasVisitedEditMeeting', '0');
    if (clientId) {
      navigate('/suitability-report/meetings/edit-meeting', {
        state: {
          meetingId: meeting.meetingAccessId,
          templateID: '739',
          reportName: meeting.meetingName,
          clientUid: clientId,
          reportUID: meeting.reportUID ? meeting.reportUID : '',
        },
      });
    }
  };
  const accessSummary = () => {
    sessionStorage.setItem('hasVisitedEditMeeting', '0');
    navigate('/suitability-report/meetings/edit-meeting', {
      state: {
        meetingId: meeting.meetingAccessId,
        templateID: '739',
        reportName: meeting.meetingName,
        clientUid: meeting.clientID,
        reportUID: meeting.reportUID ? meeting.reportUID : '',
      },
    });
  };
  const triggerDownload = (blob, fileName) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // Set the file to be downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url); // Free up memory
  };
  const getMicrosoftToken = async () => {
    const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
    return tokenResponse.accessToken;
  };
  const menuItemClicked = async (actionName) => {
    let mtoken = await getMicrosoftToken();
    if (actionName === 'Access meeting transcript') {
      let params = {
        meetingID: meeting.meetingAccessId,
      };
      const response = await getMicrosfotMeetingTranscript(mtoken, params);
      // Check if the response is a string
      if (typeof response === 'string') {
        // Convert the string to a Blob
        const blob = new Blob([response], { type: 'text/plain' });

        // Trigger download
        triggerDownload(blob, 'transcript.txt');
      } else {
        console.error('Unexpected response type:', response);
      }
    } else if (actionName === 'Delete summary from Avenir') {
      const responseBody = {
        meetingID: meeting.meetingAccessId,
      };
      const res = await deleteMeetingSummary(mtoken, responseBody); // eslint-disable-line no-unused-vars
      updateMeetingList();
    }
  };
  const createNewClient = async (res) => {
    const uid = await createClient(res);
    updateClientList();
    setClientId(uid.clientUID);
    const fullName = `${res.firstName} ${res.lastName}`;
    setClientName(fullName);
    setShowCreateClientModal(false);
    setShowClientList(false);
    serErrorMessage('');
  };
  const selectedClient = (client) => {
    const fullName = `${client.firstName} ${client.lastName}`;
    setClientName(fullName);
    setClientId(client.clientUID);
    setShowClientList(false);
    serErrorMessage('');
  };
  const handleClientNameChange = (e) => {
    let searchResult = e.target.value;
    setClientName(e.target.value);
    setShowClientList(true);
    // Clear the previous debounce timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    // Set a new debounce timer
    setShowClientsLoader(true)
    debounceTimer.current = setTimeout(async () => {
      const response =  await searchClientLists(searchResult); // Call the API only after delay
      setTimeout(() =>{
        setFilteredCLientlist(response.slice(0, 5)); // Filter after update
        setShowClientsLoader(false)
        if (!e.target.value) {
          setClientId('');
        }
      },[])
    }, 1000); // 1-second delay
  };
  const searchClientLists = async (val) => {
    const res = await getUserClientList(val, 200);
    setClientList(res.clients);
    return res.clients
  };
  const getFormatedDate = (meetingDate) => {
    const date = new Date(meetingDate);
    // Get day, month, year, hours, and minutes
    const day = date.getDate();
    const month = date.toLocaleString('en-GB', { month: 'long' });
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0'); // Ensures two-digit format
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensures two-digit format
    // Format the date manually
    const formattedDate = `${day} ${month} ${year} ${hours}:${minutes}`;
    return formattedDate;
  };
  useEffect(() => {
    sessionStorage.setItem('hasVisitedEditMeeting', '0');
  }, []);
  return (
    <Box
      bg="white"
      borderRadius="20px"
      boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
      overflow="hidden"
      mb={5}
      p="15px"
      w="365px"
    >
      <Flex justifyContent="space-between" alignItems="flex-start" h="60px">
        <Flex flexDirection="column">
          <Text
            sx={{ textStyle: 'dmsansText' }}
            color="#1B2559"
            fontWeight="700"
          >
            {meeting.meetingName}
          </Text>
          <Text
            sx={{ textStyle: 'dmsansNormal' }}
            color="#A3AED0"
            fontWeight="400"
            mt="4px"
          >
            {getFormatedDate(meeting.date)}
          </Text>
        </Flex>
        {actions.length ? (
          <Menu mt="-4px">
            <MenuButton
              as={IconButton}
              icon={<FiMoreVertical />}
              variant="ghost"
              p={0}
              m={0}
              border="0px"
              minWidth="auto"
            />
            <MenuList borderRadius="16px" p="4px">
              {actions.map((action, index) => (
                <MenuItem
                  key={index}
                  onClick={() => menuItemClicked(action)}
                  sx={{ textStyle: 'dmsansNormal' }}
                  color="black"
                  fontSize="14px"
                  fontWeight="400"
                  _hover={{ bg: 'avenirGrey.100' }}
                >
                  {action}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ) : null}
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mt={3}>
        <Flex alignItems="center">
          <Box width="25px" height="21px" flexShrink="0" mr="4px" >
            {
              tooltipText.length ?
              (<GoPeople style={{ width: '100%', height: '100%', color: "#A3AED0" }} />) : null
            }
          </Box>
          <Tooltip
            label={
              <Text
                fontWeight="400"
                sx={{ textStyle: 'dmsansNormal' }}
                fontSize="14px"
                whiteSpace="pre-line"
                color="black"
              >
                {tooltipText}
              </Text>
            }
            placement="right"
            bg="white" // Set tooltip background to white
            borderRadius="12px"
            p="2"
          >
            <Text
              sx={{ textStyle: 'dmsansNormal' }}
              color="#A3AED0"
              fontWeight="400"
              whiteSpace="pre-line" // Ensures each name appears on a new line
              cursor="pointer"
            >
              {displayText}
            </Text>
          </Tooltip>
        </Flex>
        {meeting.summarizedMeeting ? (
          <Button
            size="sm"
            bg="white"
            _hover={{ bg: 'white' }}
            color="#4E13C3"
            border="1px solid"
            borderColor="#4E13C3"
            p="18px 20px"
            onClick={() => accessSummary()}
            ml="20px"
            mr="5px"
          >
            Access Summary
          </Button>
        ) : (
          <Button
            size="sm"
            bg="#11047A"
            _hover={{ bg: '#11047A' }}
            color="white"
            p="18px 20px"
            onClick={onOpen}
            ml="20px"
            mr="5px"
          >
            Create summary
          </Button>
        )}
      </Flex>
      <Modal isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent br="15px" maxW="554px" maxH="270px">
          <ModalHeader>
            <Text sx={{ textStyle: 'dmsansHeading' }} fontSize="24px">
              Select a client for this summary
            </Text>
            <Text
              sx={{ textStyle: 'dmsansHeading' }}
              fontSize="16px"
              color="secondaryGray.900"
              fontWeight="400"
              lineHeight="26px"
            >
              Either create a client or select an existing one by clicking below
            </Text>
          </ModalHeader>
          <ModalBody>
            <Input
              placeholder="Add a new client"
              w="70%"
              borderRadius="16px"
              onChange={handleClientNameChange}
              onFocus={handleClientNameChange}
              onBlur={() => setTimeout(handleClientNameBlur, 300)}
              value={clientName}
              onKeyDown={(e) => {
                if (e.key === 'Enter') addNewCleint();
              }}
              // onChange={(e) => setClient(e.target.value)}
            />
            {showClientList ? (
              <Box
                p={4}
                mt="4px"
                boxShadow="md"
                bg="white"
                w="300px"
                borderRadius="16px"
                position="absolute"
                zIndex="123"
              >
                <VStack align="start" spacing={4}>
                  <HStack spacing={2} onClick={addNewCleint} cursor="pointer">
                    <Icon as={LuPlusCircle} boxSize="17px" color="#1B2559" />
                    <Text
                      color="#1B2559"
                      sx={{ textStyle: 'dmsansNormal' }}
                      fontWeight="400"
                      fontSize="14px"
                    >
                      Add a new client
                    </Text>
                  </HStack>
                  {!showClientsLoader ? (
                filteredCLientlist.length > 0 ? (
                  <Flex flexDirection="column" w="100%">
                    {filteredCLientlist.map((client, index) => (
                      <Flex
                        key={client.clientUID} // Use clientUID as a unique key
                        onClick={() => selectedClient(client)}
                        p="5px 10px"
                        _hover={{
                          backgroundColor: 'avenirGrey.200',
                        }}
                      >
                        <Text
                          cursor="pointer"
                          color="#1B2559"
                          sx={{ textStyle: 'dmsansNormal' }}
                          fontWeight="400"
                          fontSize="14px"
                        >
                          {client.firstName} {client.lastName}
                        </Text>
                        {client.isIOClient ? (
                          <Image
                            src={IntellifloShortImage}
                            alignSelf="center"
                            justifySelf="center"
                            w="18px"
                            h="18px"
                            ml="10px"
                          />
                        ) : null}
                      </Flex>
                    ))}
                  </Flex>
                ) : null) : (
                  <Flex alignItems="center" justifyContent="center" w="100%">
                    <Spinner
                      thickness="2px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="avenirSuitabilityColor.800"
                      width="15px" // Set custom width
                      height="15px" // Set custom height
                    />
                    <Text ml="15px">Searching</Text>
                  </Flex>
                )}
                </VStack>
              </Box>
            ) : null}
          </ModalBody>

          <ModalFooter>
            <Flex
              alignItems="flex-end"
              flexDirection="row-reverse"
              w="100%"
              mt="30px"
            >
              <Button
                sx={{ textStyle: 'dmsansText' }}
                fontSize="14px"
                width="160px"
                h="40px"
                border="1px solid"
                borderColor="avenirSuitabilityColor.600"
                color="white"
                bg="avenirSuitabilityColor.600"
                _hover={{ bg: 'avenirSuitabilityColor.600' }}
                ml="10px"
                onClick={() => createSummary()}
              >
                Create Summary
              </Button>
              <Button
                sx={{ textStyle: 'dmsansText' }}
                fontSize="14px"
                width="100px"
                h="40px"
                border="1px solid"
                borderColor="avenirSuitabilityColor.600"
                color="avenirSuitabilityColor.600"
                bg="white"
                mr="10px"
                _hover={{ bg: 'white' }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {showCreateClientModal ? (
        <V1CreateNewClient
          closeClient={closeClient}
          createNewClient={createNewClient}
          createdClientName={createdClientName}
        ></V1CreateNewClient>
      ) : null}
    </Box>
  );
};
export default MeetingCard;
