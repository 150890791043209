const FE_PROXY_LINKING_BE_PRE_PROD = "https://suitability.web.app/"
const LOCAL_SERVER = "" // In dev, we use axios and a proxy to manage the connexion to the url. This is manatained in package.json. 

// TODO : Change this is you want to move to dev/prod environment 
const ENVIRONMENT = "prod"

export const SERVER_URL = ENVIRONMENT === "prod" ? FE_PROXY_LINKING_BE_PRE_PROD : LOCAL_SERVER +'/'

// Liciense keys
export const SYNCFUSION_LICIENSE_KEY = "ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5RdkBjUHtfcnxTR2VY"

// Templete Name
export const SUITABILITY_TEMPLATE_NAME = "pension-switch"
// Intelifflo Constants C6FX!f0Sk5@7L3vLO8E7Z3cOg$@12X
export const InteliffloClientId = 'app-ec59507-acf-42b8c16078e042c3a1d379b28e3b4f94'; // Replace with your client_id
export const InteliffloAuthorizationCode = 'your-authorization-code'; // Replace with your authorization code
export const InteliffloRedirectUrl = 'https://suitability.web.app/oauth2/callback'; // Replace with your redirect URI
export const InteliffloTokenUrl = 'https://identity.gb.intelliflo.net/core/connect/token';
export const InteliffloAuthURL = 'https://identity.gb.intelliflo.net/core/connect/authorize';

// URL for backend calls 
export const API_PORTFOLIO_URL = SERVER_URL + "api/portfolio/"
export const API_CREATE_SUITABILITY_REPORT =  SERVER_URL + 'api/reports/'
export const API_INITIATE_SUITABILITY_REPORT = SERVER_URL + "api/reports/heartbeats/"
export const API_SECTIONS_URL_BASE = SERVER_URL + 'api/reports/sections/'
export const API_SUITABILITY_PROGRESS = SERVER_URL + 'api/reports/progress/'
export const API_SUBSECTION_QUESTION = SERVER_URL + 'api/questions/'
export const API_SUITABILITY_SERVER_ANSWER = SERVER_URL + 'api/reports/server-answers/'
export const API_SUITABILITY_SUBSECTION_USER_ANSWER = SERVER_URL + 'api/reports/user-answers/'
export const API_SUITABILITY_HEADERS_FOOTER = SERVER_URL + 'api/hf/'
export const API_SUITABILITY_RESET_REPORT = SERVER_URL + 'api/reports/delete/'
export const API_SUITABILITY_ISIN_SEARCH = SERVER_URL + 'api/funds/details/'
export const API_SUITABILITY_UPLOAD_REPORT = SERVER_URL + 'api/pdfs/'
export const API_SUITABILITY_UPLOAD_STATE = SERVER_URL + 'api/reports/save/'
export const API_SUITABILITY_CREATE_CONTEXT = SERVER_URL + 'api/pdfs/context/'
export const API_SUITABILITY_GET_CONTEXT = SERVER_URL   + 'api/reports/save/'
export const API_SUITABILITY_UPLOAD_TEXT_REPORT = SERVER_URL+ 'api/uploadtext/'
export const API_SUITABILITY_UPLOAD_PDF_SECTION_DETAILS = SERVER_URL + 'api/pdfs/'
export const API_SUITABILITY_REMOVE_PDF_SECTION_FILE = SERVER_URL + 'api/pdfs/'
export const API_SUITABILITY_GET_CLIENTS_DETAILS = SERVER_URL + 'api/users/info/'
export const API_SUITABILITY_GET_USER_FOOTER_DETAILS = SERVER_URL + 'api/users/footer/'
export const API_SUIRABILITY_POST_USER_FOOTER_DETAILS = SERVER_URL + 'api/users/footer/'
export const API_SUITABILITY_POST_USER_HEADER_DETAILS = SERVER_URL + 'api/users/header/'
export const API_SUITABILITY_GET_USER_HEADER_DETAILS = SERVER_URL + 'api/users/header/'
export const API_SUITABILITY_CREATE_USER = SERVER_URL + 'api/users/'
export const API_SUTIABILITY_GET_REPORT_LIST = SERVER_URL + 'api/reports/list'
export const API_SUITABILITY_GET_CLIENTS_LIST = SERVER_URL + 'api/clients'
export const API_SUITABILITY_CREATE_CLIENT = SERVER_URL + 'api/clients/create/'
export const API_SUITABILITY_STRIPE_CHECKOUT_LINK = SERVER_URL + 'api/stripe/checkout-link'
export const API_SUITABILITY_STRIPE_PROTAL_LINK = SERVER_URL +'api/stripe/portal-link'
export const API_SUITABILITY_SFDT_HEADER_DETAILS = SERVER_URL + 'api/sfdt/header'
export const API_SUITABILITY_FUND_ONGOING_CHARGE = SERVER_URL + 'api/funds/charges'
export const API_SUITABILITY_REPORT_EXPORT_DOC = SERVER_URL + 'api/exports/docx'
export const API_SUITABILITY_REPORT_EXPORT_PDF = SERVER_URL + 'api/exports/pdf'
export const API_SUITBILITY_REPORT_GET_USER_COLOR = SERVER_URL + 'api/users/color/'
export const API_SUITABILITY_REPORT_POST_USER_COLOR = SERVER_URL + 'api/users/color/'
export const API_SUITABLILITY_REPORT_USER_CAN_CREATE_REPORT = SERVER_URL + 'api/users/permissions'
export const API_SUITABILITY_REPORT_TEMPLATE_LIST = SERVER_URL + 'api/templates/list'
export const API_SUITABILITY_MICORSOFT_MEETINGS = SERVER_URL + 'api/meetings/microsoft/'
export const API_SUITABILITY_MICTOSOFT_GET_TRANSCTIPT = SERVER_URL +'api/meetings/transcript/'
export const API_SUITABILITY_MICROSOFT_DELETE_MEETING = SERVER_URL + 'api/meetings/delete/'
export const API_SUITABILITU_REPORT_EXPORT_XLS= SERVER_URL + 'api/exports/xls'
export const API_SUITABILITY_REPORT_INTELIFFLO_USER = SERVER_URL + 'api/users/intelliflo'
