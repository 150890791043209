import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Text,
  Input,
  Flex,
  Icon,
  Image,
  VStack,
  HStack,
  Spinner
} from '@chakra-ui/react';
import IntellifloShortImage from '../../../../assets/img/SuitabilityReport/IntellifloShortImage.png';
import { LuPlusCircle } from 'react-icons/lu';
import V1CreateNewClient from '../V1CreateNewClient'
import {createClient, getUserClientList} from '../../../../services/apiServices'
const ClientListComponent = ({ selectedUserId }) => {
  const debounceTimer = useRef(null); // useRef to store timeout
  const [clientName, setClientName] = useState('');
  const [clientErrorMessage, setClientErrorMessage] = useState('');
  const [showClientList, setShowClientList] = useState(false);
  const [filteredCLientlist, setFilteredCLientlist] = useState([]);
  const [showCreateClientModal, setShowCreateClientModal] = useState(false);
  const [clientList, setClientList] = useState();
  const [clientId, setClientId] = useState('');
  const [showClientsLoader, setShowClientsLoader] = useState(false)
  const [createdClientName, setCreatedClientName] = useState({
    firstname: '',
    lastname: '',
  });
  const selectedClient = (client) => {
    const fullName = `${client.firstName} ${client.lastName}`;
    setClientName(fullName);
    setClientId(client.clientUID);
    setShowClientList(false);
    selectedUserId(client.clientUID)
  };
  const closeClient = () => {
    setShowCreateClientModal(false);
  };

  const handleClientNameChange = async (e) => {
    setClientErrorMessage('');
    let searchResult = e.target.value;
    setClientName(e.target.value);
    setShowClientList(true);
    // Clear the previous debounce timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    // Set a new debounce timer
    setShowClientsLoader(true)
    debounceTimer.current = setTimeout(async () => {
      const response =  await updateClientList(searchResult); // Call the API only after delay
      setTimeout(() =>{
        setFilteredCLientlist(response.slice(0, 5)); // Filter after update
        setShowClientsLoader(false)
        if (!e.target.value) {
          setClientId('');
        }
      },[])
    }, 1000); // 1-second delay
  };
  const addNewCleint = () => {
    let [clientfirstname, ...rest] = clientName.split(' ');
    let clientlastname = rest.join(' ');
    setCreatedClientName({
      firstname: clientfirstname,
      lastname: clientlastname,
    });
    setShowCreateClientModal(true);
  };
  const createNewClient = async (res) => {
    const uid = await createClient(res);
    updateClientList();
    setClientId(uid.clientUID);
    const fullName = `${res.firstName} ${res.lastName}`;
    setClientName(fullName);
    setShowCreateClientModal(false);
    setShowClientList(false);
  };
  const updateClientList = async (val) => {
    const res = await getUserClientList(val, 200);
    setClientList(res.clients);
    return res.clients
  };
  const handleClientNameBlur = () => {
    setShowClientList(false);
  };
  useEffect(() => {
    updateClientList("")
  }, []);
  return (
    <Box>
      <Text
        sx={{ textStyle: 'dmsansNormal' }}
        fontWeight="500"
        fontSize="14px"
        color="#1B2559"
        letterSpacing="-0.28px"
        lineHeight="28px"
      >
        Which client you met?
      </Text>
      <Input
        value={clientName}
        placeholder="Add a new client"
        borderRadius="8px"
        mt="8px"
        borderColor={clientErrorMessage.length ? '#B20D30' : '#E6E6E6'}
        focusBorderColor={
          clientErrorMessage.length ? '#B20D30' : 'avenirSuitabilityColor.600'
        }
        onChange={handleClientNameChange}
        onFocus={handleClientNameChange}
        onBlur={() => setTimeout(handleClientNameBlur, 300)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') addNewCleint();
        }}
      />
      {clientErrorMessage.length ? (
        <Text
          mt="2px"
          sx={{ textStyle: 'manropeHeading' }}
          color="#B20D30"
          fontSize="12px"
          fontWeight="600"
        >
          {clientErrorMessage}
        </Text>
      ) : null}
      {showClientList ? (
        <Box
          p="10px"
          mt="4px"
          boxShadow="md"
          bg="white"
          w="300px"
          borderRadius="16px"
          position="absolute"
          zIndex="123"
          ml="-40px"
        >
          <VStack align="start">
            <HStack
              spacing={2}
              onClick={addNewCleint}
              cursor="pointer"
              p="5px 10px"
            >
              <Icon as={LuPlusCircle} boxSize="17px" color="#1B2559" />
              <Text
                color="#1B2559"
                sx={{ textStyle: 'dmsansNormal' }}
                fontWeight="400"
                fontSize="14px"
              >
                Add a new client
              </Text>
            </HStack>
            {!showClientsLoader ? (
            filteredCLientlist.length > 0 ? (
              <Flex flexDirection="column" w="100%">
                {filteredCLientlist.map((client, index) => (
                  <Flex
                    key={client.clientUID} // Use clientUID as a unique key
                    onClick={() => selectedClient(client)}
                    p="5px 10px"
                    _hover={{
                      backgroundColor: 'avenirGrey.200',
                    }}
                  >
                    <Text
                      cursor="pointer"
                      color="#1B2559"
                      sx={{ textStyle: 'dmsansNormal' }}
                      fontWeight="400"
                      fontSize="14px"
                    >
                      {client.firstName} {client.lastName}
                    </Text>
                    {client.isIOClient ? (
                      <Image
                        src={IntellifloShortImage}
                        alignSelf="center"
                        justifySelf="center"
                        w="18px"
                        h="18px"
                        ml="10px"
                      />
                    ) : null}
                  </Flex>
                ))}
              </Flex>
            ) : null) : (
              <Flex alignItems="center" justifyContent="center" w="100%">
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="avenirSuitabilityColor.800"
                  width="15px" // Set custom width
                  height="15px" // Set custom height
                />
                <Text ml="15px">Searching</Text>
              </Flex>
            )}
          </VStack>
        </Box>
      ) : null}
       {showCreateClientModal ? (
        <V1CreateNewClient
          closeClient={closeClient}
          createNewClient={createNewClient}
          createdClientName={createdClientName}
        ></V1CreateNewClient>
      ) : null}
    </Box>
  );
};
export default ClientListComponent;

