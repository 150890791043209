import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import PortfolioMultiTable from './PortfolioMultiTableAnswer';
import PortfolioExcelImport from './PortfolioExcelImport';

const PortfolioTablePage = ({
  question,
  goToPreviousQuestion,
  userAnswered,
}) => {
  const [isTemplateExport, setIsTemplateExport] = useState(false);
  const [filteredPortfolioData, setFilteredPortfolioData] = useState([]);
  const [sourceType, setSourceType] = useState('Manual');
  const [portfolioData, setPortfolioData] = useState([
    {
      assetName: '',
      isin: '',
      Weight: '',
      ongoingCharge: '',
      oneY: '',
      threeY: '',
      fiveY: '',
    },
  ]);
  const UpdatePortfolioData = (val) => {
    setSourceType('Excel');
    let res = filterExcelData(val, question.answers[0].PortfolioColumnNames);
    setFilteredPortfolioData(res);
    setIsTemplateExport(false);
  };
  const updateTemplateState = (val) => {
    setIsTemplateExport(val);
  };
  const goToPreviousQuestionAnswer = () => {
    goToPreviousQuestion();
  };
  const filterExcelData = (rawdata, columnNames) => {
    const filteredData = rawdata.map((item) => {
      const filteredItem = {};
      columnNames.forEach((column) => {
        const key = column.replace(/\s/g, '_');
        filteredItem[column] = item[key] || item[column] || '';
      });
      return filteredItem;
    });
    return filteredData;
  };
  const filterData = (rawdata, columnNames) => {
    const filteredData = rawdata.map((item) => {
      const filteredItem = {};
      columnNames.forEach((column) => {
        // Map column names to keys in the object
        if (column === 'Asset Name') {
          filteredItem[column] = item['Name'] ? item['Name'] : '';
        } else if (column === 'Isin') {
          filteredItem[column] = item['IsinCode'] ? item['IsinCode'] : '';
        } else {
          const key = column.replace(/\s/g, '_'); // Replace spaces with underscores
          filteredItem[column] = item[key] || item[column] || '';
        }
      });
      return filteredItem;
    });
    return filteredData;
  };
  useEffect(() => {
    let ai_ansswer = question.answers[0].value
    ? question.answers[0].value
    : question.answers[0].valueIO
    ? question.answers[0].valueIO
    : question.answers[0].AIAnswer;
    let formated_ai_answer = ai_ansswer
      .replace(/'/g, '"') // Replace single quotes with double quotes
      .replace(/None/g, 'null') // Replace Python's None with JSON's null
      .replace(/"([^"]+)"(?=\s*:)/g, '"$1"'); // Ensure property names are properly quoted
    if(formated_ai_answer.length && formated_ai_answer!== "{}"){
      let res = JSON.parse(formated_ai_answer);
      if (res?.data?.portfolioDetails) {
        formated_ai_answer = res.data.portfolioDetails;
      }
      let filteredPortfolioData =
        res && res?.data?.portfolioDetails
          ? filterExcelData(res.data.portfolioDetails, question.answers[0].PortfolioColumnNames)
          : filterData(res, question.answers[0].PortfolioColumnNames);
      setFilteredPortfolioData(filteredPortfolioData);
    }
  }, []);
  return (
    <Box>
      {!isTemplateExport ? (
        <PortfolioMultiTable
          question={question}
          updateTemplateState={updateTemplateState}
          portfolioData={portfolioData}
          goToPreviousQuestionAnswer={goToPreviousQuestionAnswer}
          filteredPortfolioData={filteredPortfolioData}
          sourceType={sourceType}
          userAnswered={userAnswered}
        ></PortfolioMultiTable>
      ) : (
        <PortfolioExcelImport
          UpdatePortfolioData={UpdatePortfolioData}
          updateTemplateState={updateTemplateState}
          question={question}
        ></PortfolioExcelImport>
      )}
    </Box>
  );
};
export default PortfolioTablePage;
