import React, { useState } from 'react';
import {
  Box,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Input,
  Flex,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import ClientListComponent from './ClientListComponent'
import {CreateReportAPI} from '../../../../services/apiServices'

const MeetingAudioUploadCard = () => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.mauth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reportName, setReportName] = useState('');
  const [errorMessage, serErrorMessage] = useState('');
  const [clientId, setClientId] = useState('');
  const handleReportNameChange = (e) => {
    setReportName(e.target.value);
    serErrorMessage('');
  };
  const createSummary = async () => {
    try {
      let res = {
        clientUID: clientId,
        templateUID: '739',
        reportName: reportName,
        isMeetingFilesToBeUploaded: true
      };
      const response = await CreateReportAPI(res, userInfo.mid);
      // Check if response is valid and contains reportUID
      if (response && response.reportUID) {
        const clientObject = {
          reportUID: response,
          templateUID: '739',
          reportName: reportName,
        };
        const base64Encoded = CryptoJS.enc.Base64.stringify(
          CryptoJS.enc.Utf8.parse(JSON.stringify(clientObject))
        );
        navigate(`/suitability-report/edit-report/${base64Encoded}`);
      } else {
        console.error("Invalid response received:", response);
      }
    } catch (error) {
      console.error("Error creating summary:", error);
    }
  };
  
  const selectedUserId = (val) =>{
    setClientId(val)
  }
  return (
    <Box>
      <Button
        onClick={onOpen}
        bg="white"
        color="avenirSuitabilityColor.600"
        p="8px 18px"
        sx={{ textStyle: 'manropeHeading' }}
        fontSize="14px"
        _hover={{ bg: 'white' }}
        border="1px solid"
        borderColor="avenirSuitabilityColor.600"
        borderRadius="12px"
      >
        Upload a recording manually
      </Button>
      <Modal isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent br="15px" maxW="554px" minH="max-content">
          <ModalHeader>
            <Text sx={{ textStyle: 'dmsansHeading' }} fontSize="24px">
              Select the client you met
            </Text>
            <Text
              sx={{ textStyle: 'dmsansHeading' }}
              fontSize="16px"
              color="secondaryGray.900"
              fontWeight="400"
              lineHeight="26px"
            >
              Either create a client or select an existing one by clicking below
            </Text>
          </ModalHeader>
          <ModalBody>
            <Flex w="100%" justifyContent="space-between">
              <Box w="48%">
                <Text
                  sx={{ textStyle: 'dmsansNormal' }}
                  fontWeight="500"
                  fontSize="14px"
                  color="#1B2559"
                  letterSpacing="-0.28px"
                  lineHeight="28px"
                >
                  Meeting Name
                </Text>
                <Input
                  value={reportName}
                  placeholder="Suitability 02-04-2024"
                  borderRadius="8px"
                  mt="8px"
                  focusBorderColor="avenirSuitabilityColor.600"
                  borderColor={'#E6E6E6'}
                  onChange={handleReportNameChange}
                />
              </Box>
              <Box w="48%">
                {/* {clientList.length} */}
                <ClientListComponent
                    key="clientListcomponent"
                    selectedUserId ={selectedUserId}
                ></ClientListComponent>
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              alignItems="flex-end"
              flexDirection="row-reverse"
              w="100%"
              mt="30px"
            >
              <Button
                sx={{ textStyle: 'dmsansText' }}
                fontSize="14px"
                width="160px"
                h="40px"
                border="1px solid"
                borderColor="avenirSuitabilityColor.600"
                color="white"
                bg="avenirSuitabilityColor.600"
                _hover={{ bg: 'avenirSuitabilityColor.600' }}
                ml="10px"
                onClick={() => createSummary()}
              >
                Create Summary
              </Button>
              <Button
                sx={{ textStyle: 'dmsansText' }}
                fontSize="14px"
                width="100px"
                h="40px"
                border="1px solid"
                borderColor="avenirSuitabilityColor.600"
                color="avenirSuitabilityColor.600"
                bg="white"
                mr="10px"
                _hover={{ bg: 'white' }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default MeetingAudioUploadCard;

