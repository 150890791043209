import { Flex, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import V1SideNav from '../components/V1SideNav';
import IntegrationSection from '../components/v3Integrations/IntegrationSection'
const SuitabilityIntegrations = () => {
    const userInfo = useSelector((state) => state.usrinf);
  return (
    <Flex w="100%">
      <V1SideNav></V1SideNav>
      <Flex
        p="20px"
        w="80vw"
        bg="white"
        flexDirection="column"
        h="100vh"
      >
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          color="#707EAE"
          fontWeight="500"
          fontSize="14px"
          lineHeight="24px"
        >
           {userInfo.organisationName} / Integrations
        </Text>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="34px"
          color="#2B3674"
          mb="5px"
          lineHeight="42px"
        >
          Connect your workspace
        </Text>
        <IntegrationSection></IntegrationSection>
      </Flex>
    </Flex>
  );
};

export default SuitabilityIntegrations;
