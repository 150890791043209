import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Flex,
  Text,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
} from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import V2UploadFIles from './V2UploadFiles';
import { postCreateContext } from 'services/apiServices';
const V2UploadDocuments = ({
  uploadPdfsectiondetails,
  sectionUploadChange,
}) => {
  const childRefs = useRef([]); // Reference to child component
  const currentReportUID = useSelector((state) => state.reportUID);
  const [nonCollapseUploadDocumentFiles, setNonCollapseUploadDocumentFiles] =
    useState([]);
  const [CollapseUploadDocumentFiles, setCollapseUploadDocumentFiles] =
    useState([]);
  const [isOneFileUploaded, setIsOneFileUploaded] = useState(0);
  const [disableSubmitButtonTemp, setDisableSubmitButtonTemp] = useState(0);
  const [showUploadOneDocError, setShowUploadOneDocError] = useState(false);
  const [showCreateingReport, setShowCreateingReport] = useState(false);
  const [documentsUploaded, setDocumentsUploaded] = useState([])
  const upadteIsFileUploaded = (val) => {
    setIsOneFileUploaded(isOneFileUploaded + val);
  };
  const updateDisableSubmitButtonTemp = (val) => {
    setDisableSubmitButtonTemp((prev) => {
      const updatedValue = prev + val; // Use the previous state value
      return updatedValue; // Return the new state
    });
  };
  const addConsolidatedNumber = () => {
    setCollapseUploadDocumentFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.forEach((item) => {
        const firstFalseFile = item.fileList.find(
          (file) => file.isPresent === false,
        );
        if (firstFalseFile) {
          firstFalseFile.isPresent = true;
        }
      });
      return updatedFiles;
    });
  };
  const updateConsolidationReports = (fileName) => {
    setCollapseUploadDocumentFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.forEach((item) => {
        const trueFiles = item.fileList.filter(
          (file) => file.isPresent === true,
        );
        if (trueFiles.length > 0) {
          const lastTrueFile = trueFiles[trueFiles.length - 1];
          lastTrueFile.isPresent = false;
        }
      });
      return updatedFiles;
    });
    const keys = Object.keys(documentsUploaded)
    const tragetIndex = keys.indexOf(fileName)
    if(tragetIndex !== -1){
      setDocumentsUploaded((prevFiles) => {
        let tempFiles = { ...prevFiles }; 
        tempFiles[fileName] = [];
        // Shift the next key's values into the previous key
        for (let i = tragetIndex; i < keys.length - 1; i++) {
          tempFiles[keys[i]] = tempFiles[keys[i + 1]];
        }
        // Empty the last key
        tempFiles[keys[keys.length - 1]] = []; 
        setTimeout(() =>{
          childRefs.current.forEach((ref) => {
            if (ref) {
              ref.childFunction(tempFiles);
            }
          });
        }, 100)
        return tempFiles;
      });
    }
  };
  const updateDocumentsUploaded = (val, section) =>{
    if(section && section !== NaN && CollapseUploadDocumentFiles.length){
      setDocumentsUploaded((prevFiles) =>{
        let tempFiles = prevFiles
        tempFiles[section] = val
        return tempFiles
      })
    }
  }
  const submitDocuments = async () => {
    setTimeout(async () => {
      let ConsolidatedFiles = []
      if(CollapseUploadDocumentFiles.length){
        CollapseUploadDocumentFiles[0].fileList.map((fileName, index) =>{
          let res = {
            category: fileName.category,
            description: fileName.description,
            files: documentsUploaded[fileName.category]
          }
          ConsolidatedFiles.push(res)
        })
      }
      if (isOneFileUploaded && disableSubmitButtonTemp <= 0) {
        const res = {
          reportUID: currentReportUID.reportUID,
          consolidatedFiles: ConsolidatedFiles
        };
        setShowCreateingReport(true);
        await postCreateContext(res);
        setShowCreateingReport(false);
        sectionUploadChange(1);
      } else {
        setShowUploadOneDocError(true);
        setTimeout(() => {
          setShowUploadOneDocError(false);
        }, [3000]);
      }
    }, [100]);
  };
  useEffect(() => {
    let res = uploadPdfsectiondetails.filter((items) => !items.isCollapsed);
    setNonCollapseUploadDocumentFiles(res);
    let files = uploadPdfsectiondetails
      .filter((items) => items.isCollapsed)
      .map((item) => {
        return {
          ...item,
          fileList: item.fileList.map((file, index) => ({
            ...file,
            isPresent: index === 0, // Set true for the first file, false for all others
          })),
        };
      });
    setCollapseUploadDocumentFiles(files);
  }, [uploadPdfsectiondetails]);
  return (
    <Flex
      p="20px"
      bg="white"
      w="100%"
      flexDirection="column"
      h="100vh"
      overflow="auto"
    >
      <Text
        sx={{ textStyle: 'manropeHeading' }}
        color="#707EAE"
        fontWeight="500"
        fontSize="14px"
        lineHeight="24px"
      >
        Dashboard / New Report
      </Text>
      <Text
        sx={{ textStyle: 'manropeHeading' }}
        fontSize="34px"
        color="#2B3674"
        mb="5px"
        lineHeight="42px"
      >
        Upload Files
      </Text>

      {/* Four Upload Sections */}

      <Flex
        wrap="wrap"
        justifyContent="space-between"
        bg="white"
        mt="15px"
        gap="20px"
        w="100%"
      >
        {nonCollapseUploadDocumentFiles &&
          nonCollapseUploadDocumentFiles.map((uploadDetails, index) => (
            <V2UploadFIles
              key={uploadDetails.collapsedTitle || index}
              uploadDetails={uploadDetails}
              fileName={uploadDetails.collapsedTitle}
              documentlist={uploadDetails.fileList[0].documents}
              showDeleteOption={false}
              updateConsolidationReports={updateConsolidationReports}
              upadteIsFileUploaded={upadteIsFileUploaded}
              updateDisableSubmitButtonTemp={updateDisableSubmitButtonTemp}
              updateDocumentsUploaded ={updateDocumentsUploaded}
              ref={(el) => (childRefs.current[index] = el)}
              CollapseUploadDocumentFiles={CollapseUploadDocumentFiles}
            />
          ))}
        {CollapseUploadDocumentFiles.map((uploadDetails, index) =>
          uploadDetails.fileList
            .filter((consolidatedFiles) => consolidatedFiles.isPresent) // Add the filter condition here
            .map((consolidatedFiles, fileindex) => (
              <V2UploadFIles
                key={consolidatedFiles.collapsedTitle || fileindex}
                fileName={consolidatedFiles.category}
                documentlist={consolidatedFiles.documents}
                updateConsolidationReports={updateConsolidationReports}
                showDeleteOption={fileindex > 0 ? true : false}
                upadteIsFileUploaded={upadteIsFileUploaded}
                updateDisableSubmitButtonTemp={updateDisableSubmitButtonTemp}
                updateDocumentsUploaded ={updateDocumentsUploaded}
                ref={(el) => (childRefs.current[fileindex] = el)}
                CollapseUploadDocumentFiles={CollapseUploadDocumentFiles}
              />
            )),
        )}
        {/* Add Pension to Consolidate */}
        {CollapseUploadDocumentFiles.length ? (
          <Flex
            bg="white"
            border="1px dashed #E2E8F0"
            borderRadius="12px"
            p="20px"
            w="48%"
            h="40vh"
            mb={6}
            justify="center"
            align="center"
            textAlign="center"
            onClick={() => addConsolidatedNumber()}
          >
            <Flex direction="column" align="center">
              <Text fontWeight="bold" fontSize="lg" mb={2} color="#1B2559">
                Add pension to consolidate
              </Text>
              <IconButton
                mt="15px"
                icon={<FiPlus size="24px" />}
                isRound
                size="lg"
                variant="outline"
                colorScheme="purple"
              />
            </Flex>
          </Flex>
        ) : null}
      </Flex>
      {/* Submit Button */}
      <Flex justifyContent="flex-end" mt="auto">
        <Flex flexDirection="column" alignItems="end">
          {showUploadOneDocError ? (
            <Text
              sx={{ textStyle: 'manropeHeading' }}
              color="#B20D30"
              fontSize="14px"
              fontWeight="600"
            >
              {isOneFileUploaded ? '' : 'Please upload at least one document'}
            </Text>
          ) : null}
          <Button
            w="170px"
            bg="avenirSuitabilityColor.600"
            color="white"
            sx={{ textStyle: 'dmsansHeading' }}
            fontSize="14px"
            fontWeight="500"
            letterSpacing="-0.28px"
            _hover={{ bg: 'avenirSuitabilityColor.600' }}
            onClick={submitDocuments}
          >
            {disableSubmitButtonTemp > 0 ? (
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="gray.200"
                color="avenirSuitabilityColor.800"
                width="15px" // Set custom width
                height="15px" // Set custom height
              />
            ) : (
              'Submit Documents'
            )}
          </Button>
        </Flex>
      </Flex>
      <Modal isOpen={showCreateingReport}>
        <ModalOverlay />
        <ModalContent style={{ marginTop: '35vh' }}>
          <ModalBody>
            <Flex
              h="17vh"
              borderRadius="16px"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Spinner
                thickness="8px"
                speed="0.65s"
                emptyColor="gray.200"
                color="avenirSuitabilityColor.800"
                width="55px" // Set custom width
                height="55px" // Set custom height
              />
              <Text
                sx={{ textStyle: 'manropeHeading' }}
                fontSize="20px"
                lineHeight="30px"
                fontWeight="500"
                letterSpacing="-0.4px"
                mt="20px"
                color="black"
              >
                Extracting data from your files
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default V2UploadDocuments;
